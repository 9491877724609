import { useEffect } from 'react';

import styles from './Base.module.css';

import background from '../images/background.png';
import banner from '../images/banner.png';
import logoDiscord from '../images/socials/discord-mark-blue.png';
import logoTwitch from '../images/socials/TwitchGlitchPurple.png';
import logoX from '../images/socials/x-logo-black.png';
import logoYouTube from '../images/socials/yt_icon_rgb.png';

export default function Base({ children }) {
    useEffect(() => {
        document.body.style.backgroundImage = `url(${background})`;

        return () => {
            document.body.style.backgroundImage = null;
        }
    });

    return <div className={styles.contentStrip}>
        <a href="/"><img src={banner} className={styles.banner} alt="SudokuCon" /></a>
        <div className={styles.navBar}>
            <a className={styles.navButton} href="/about">About Us</a>
            <a className={styles.navButton} href="/tickets">Tickets</a>
            <a className={styles.navButton} href="/schedule">Schedule</a>
            <a className={styles.navButton} href="/donate">Donate</a>
            <a className={styles.navButton} href="/stream">Streaming</a>
        </div>
        <div className={styles.main}>
            {children}
        </div>
        <div className={styles.footer}>
            Contact Us - <a href="mailto:info@sudokucon.com">info@sudokucon.com</a><br/>
            <div className={styles.socials}>
                <a href="https://bsky.app/profile/sudokucon.bsky.social" rel="noreferrer" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 568 501"><title>Bluesky butterfly logo</title><path fill="rgb(10, 122, 255)" d="M123.121 33.664C188.241 82.553 258.281 181.68 284 234.873c25.719-53.192 95.759-152.32 160.879-201.21C491.866-1.611 568-28.906 568 57.947c0 17.346-9.945 145.713-15.778 166.555-20.275 72.453-94.155 90.933-159.875 79.748C507.222 323.8 536.444 388.56 473.333 453.32c-119.86 122.992-172.272-30.859-185.702-70.281-2.462-7.227-3.614-10.608-3.631-7.733-.017-2.875-1.169.506-3.631 7.733-13.43 39.422-65.842 193.273-185.702 70.281-63.111-64.76-33.89-129.52 80.986-149.071-65.72 11.185-139.6-7.295-159.875-79.748C9.945 203.659 0 75.291 0 57.946 0-28.906 76.135-1.612 123.121 33.664Z"></path></svg>
                </a>
                <a href="https://discord.gg/WGqaazd7Yb" rel="noreferrer" target="_blank">
                    <img src={logoDiscord} alt="Discord" />
                </a>
                <a href="https://www.twitch.tv/sudokucon" rel="noreferrer" target="_blank">
                    <img src={logoTwitch} alt="Twitch" />
                </a>
                <a href="https://twitter.com/sudokucon" rel="noreferrer" target="_blank">
                    <img src={logoX} alt="X" />
                </a>
                <a href="https://www.youtube.com/channel/UCCEoa0MgIhtt0pcf5mm3B_Q" rel="noreferrer" target="_blank">
                    <img src={logoYouTube} alt="YouTube" />
                </a>
            </div>
        </div>
    </div>
}
