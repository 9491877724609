import React, { useState } from 'react';

export default function EmailBlast()
{
    const [pass, setPass] = useState('');
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [email, setEmail] = useState('');
    const [flags, setFlags] = useState({
        self: true,
        regular: false,
        livestream: false,
    });

    async function submit() {
        const res = await fetch('/emailblast', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pass,
                subject,
                text,
                email: flags.self ? email : null,
                regular: flags.regular,
                livestream: flags.livestream,
            }),
        });
        if (res.status === 200) {
            console.log(await res.json());
            alert('SUCCESS');
        }
        else if (res.status === 403)
            alert('BAD PASS');
        else
            alert('UNKNOWN ERROR');
    }

    return <div>
        Password:
        <br/>
        <input style={{ width: '100%', maxWidth: '70em' }} type="text" value={pass} onChange={(e) => { setPass(e.target.value); }} />
        <br/>
        Subject:
        <br/>
        <input style={{ width: '100%', maxWidth: '70em' }} type="text" value={subject} onChange={(e) => { setSubject(e.target.value); }} />
        <br/>
        Body:
        <br/>
        <textarea style={{ width: '100%', maxWidth: '70em' }} rows="6" value={text} onChange={(e) => { setText(e.target.value); }} />
        <br/>
        <input type="checkbox" checked={flags.self} onChange={(e) => {
            flags.self = e.target.checked;
            setFlags({ ...flags });
        }} /> Send an email to this email address:&nbsp;&nbsp;
        <input style={{ width: '100%', maxWidth: '50em' }} type="text" value={email} onChange={(e) => { setEmail(e.target.value); }} />
        <br/>
        <input type="checkbox" checked={flags.regular} onChange={(e) => {
            flags.regular = e.target.checked;
            setFlags({ ...flags });
        }} /> Send an email to everyone who purchased an in-person ticket.
        <br/>
        <input type="checkbox" checked={flags.livestream} onChange={(e) => {
            flags.livestream = e.target.checked;
            setFlags({ ...flags });
        }} /> Send an email to everyone who purchased a livestream ticket.
        <br/>
        <button onClick={submit}>Submit</button>
    </div>
}
