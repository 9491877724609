import { TwitchPlayer } from 'react-twitch-embed';

import Base from '../components/Base.js';
import Countdown from '../components/Countdown.js';
import styles from './Home.module.css';

import ad_dimono from '../images/sponsors/TidingsOfMinos.png';
import ad_james from '../images/sponsors/ArtisanalSudoku.png';
import ad_timberlake from '../images/sponsors/SmartHobbies.png';
import ad_ffp from '../images/sponsors/FitForPuzzle.png';

const BOOKING_LINK = 'https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=DDHDHHF&groupCode=SUDOKU&arrivaldate=2025-04-03&departuredate=2025-04-06&cid=OM,WW,HILTONLINK,EN,DirectLink&fromId=HILTONLINKDIRECT';
const LIVESTREAM_START = process.env.REACT_APP_LIVESTREAM_START;
const LIVESTREAM_END = process.env.REACT_APP_LIVESTREAM_END;

function Home()
{
    let livestreamElements = null;
    const now = Date.now() / 1000;
    if (
        LIVESTREAM_START &&
        LIVESTREAM_END &&
        now > LIVESTREAM_START - 2419200 && // four weeks before livestream starts
        now < LIVESTREAM_END + 1209600 // two weeks after livestream ends
    ) {
        if (now > LIVESTREAM_END)
            livestreamElements = <>
                <div>The 24 hour fundraising livestream has ended, but you can still check out the highlights on our <a href="https://twitch.tv/sudokucon" target="_blank" rel="noreferrer">Twitch page</a>!</div>
            </>;
        else
            livestreamElements = <>
                {now > LIVESTREAM_START
                    ? <center style={{ fontSize: '1.3em' }}>24 hour fundraising livestream is live now!</center>
                    : <>
                        <center style={{ fontSize: '1.3em' }}>24 hour fundraising livestream will be live in:</center>
                        <Countdown className={styles.countdown} endTime={LIVESTREAM_START} />
                    </>
                }
                <div className={styles.buttonsContainer}>
                    <a href="/schedule" style={{display: 'flex'}}><div className={styles.livestreamButton}>Schedule</div></a>
                    <a href="https://twitch.tv/sudokucon" target="_blank" rel="noreferrer" style={{display: 'flex'}}><div className={styles.livestreamButton}>Watch on Twitch</div></a>
                </div>
                <div className={styles.twitchPlayer}>
                    <TwitchPlayer
                        channel="SudokuCon"
                        width="100%"
                        height="100%"
                        onOnline={() => { }}
                        onOffline={() => { }}
                    />
                </div>
            </>;
    }
    // TODO How to determine between this and a twitch livestream countdown ?
    if (now > LIVESTREAM_END)
        livestreamElements = <>
            <div>SudokuCon 2025 has ended, but stick around to catch the highlights on our <a href="https://www.youtube.com/channel/UCCEoa0MgIhtt0pcf5mm3B_Q" target="_blank" rel="noreferrer">YouTube channel</a>!</div>
        </>;
    else
        livestreamElements = <>
            {now > LIVESTREAM_START
                ? <center style={{ fontSize: '1.3em' }}>SudokuCon is live now!</center>
                : <>
                    <center style={{ fontSize: '1.3em' }}>SudokuCon 2025 is coming soon!</center>
                    <Countdown className={styles.countdown} endTime={LIVESTREAM_START} />
                </>
            }
            <div style={{ marginBottom: '1em' }}>Pick up your tickets on the <a href="/tickets">ticket purchase page</a>, or book your room by reaching out to Emily, the Hilton sales coordinator (phone 781-407-1643 email emily.brodeur@hilton.com). If you'd rather attend online, livestream tickets are now on sale <a href="/livestream-tickets">here</a>, or just show your support by buying merch from <a href="https://www.cafepress.com/shop/SudokuCon/products" target="_blank" rel="noreferrer">our store here</a>! See the schedule for SudokuCon 2025 <a href="/schedule">here</a>, and information on transit and nearby attractions <a href="/transit">here</a>.</div>
        </>;

    return <Base>
        {livestreamElements}
        <p>Interested in what we're doing? <a href="https://sudokucon.beehiiv.com/" target="_blank" rel="noreferrer">Check out our newsletter!</a></p>
        <p>SudokuCon is supported by:</p>
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '5%', padding: '2em 0em' }}>
            <div style={{ flex: '1 1 0', minWidth: '15em', maxWidth: '25em' }}>
                <a href="https://dimono.ca" target="_blank" rel="noreferrer">
                    <img src={ad_dimono} alt="The Tidings of Minos" width='100%' />
                </a>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '15em', maxWidth: '25em' }}>
                <a href="http://artisanalsudoku.com" target="_blank" rel="noreferrer">
                    <img src={ad_james} alt="Artisanal Sudoku" width='100%' />
                </a>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '15em', maxWidth: '25em' }}>
                <a href="https://www.youtube.com/@SmartHobbies" target="_blank" rel="noreferrer">
                    <img src={ad_timberlake} alt="Smart Hobbies" width='100%' />
                </a>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '15em', maxWidth: '25em' }}>
                <a href="https://linktr.ee/FITFORPUZZLE" target="_blank" rel="noreferrer">
                    <img src={ad_ffp} alt="Fit For Puzzle" width='100%' />
                </a>
            </div>
        </div>
    </Base>
}

export default Home;
