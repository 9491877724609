import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import 'react-phone-number-input/style.css';

import Base from '../components/Base.js';
import styles from './Tickets.module.css';

// TODO refactor
async function createOrder(data, donationInfo) {
    // Order is created on the server and the order id is returned
    return await fetch('/api/tickets/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(donationInfo),
    });
};

async function onApprove(data) {
    // Order is captured on the server and the response is returned to the browser
    return await fetch(`/api/tickets/${data.orderID}/capture`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: ''
    })
};

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

// TODO This entire page is a mess, hacked together from the tickets page
export default function StreamTickets()
{
    const [state, setState] = useState('browsing'); // browsing, terms, checkout, submitted, approved, error
    const [error, setError] = useState(null);
    const [tickets, setTickets] = useState({
        'adult': 0,
        'child': 0,
        'student': 0,
        'unwaged': 0,
    });
    const [info, setInfo] = useState({
        'names': [''],
    });
    const [discount, setDiscount] = useState('');

    const ticketCosts = {
        'adult': 30,
        'child': 10,
        'student': 10,
        'unwaged': 10,
    }

    function totalCost()
    {
        let cost = 0;
        Object.keys(tickets).forEach(type => {
            cost += tickets[type] * ticketCosts[type];
        });
        if (discount.toUpperCase() === 'SPONSOR')
            cost /= 2;
        return cost;
    }

    function checkout()
    {
        let err = [];
        const ticketCount = Object.values(tickets).reduce((a, b) => a + b, 0);
        if (ticketCount <= 0)
            err.push('Must purchase at least one ticket.');
        let missingNames = 0;
        let badNames = 0;
        info.names.forEach(name => {
            if (name === '')
                missingNames++;
            if (!name.match(EMAIL_REGEX))
                badNames++;
        });
        if (missingNames > 0)
            err.push('Must fill out the required field: Email');
        if (badNames > 0)
            err.push('Must enter a valid email address.');
        if (!err.length)
            setState('terms');
        else
            setError(err.join('\n'));
    }

    const userCreateOrder = async (data, actions) => {
        const res = await createOrder(data, {
            type: 'stream',
            tickets,
            info,
            discount,
            amount: totalCost(),
        });
        if (res.status === 201) {
            return await res.json().then((order) => order.id);
        }
        const resJson = await res.json();
        setState('error');
        setError(resJson.error);
    }

    const userApprove = async (data, actions) => {
        setState('submitted');
        const res = await onApprove(data);
        const resJson = await res.json();
        if (res.status === 201) {
            setState('approved');
            return resJson;
        }
        setState('error');
        setError(resJson.message);
    }

    function ticketCounter(id)
    {
        function setTicketCount(value) {
            if (value < 0)
                return;
            const newTickets = { ...tickets, [id]: value };
            setTickets(newTickets);
            let ticketCount = Object.values(newTickets).reduce((a, b) => a + b, 0);
            if (ticketCount < 1)
                ticketCount = 1;
            let names = info.names;
            if (names.length > ticketCount)
                names = names.slice(0, ticketCount);
            while (names.length < ticketCount)
                names.push('');
            setInfo({ ...info, names });
        }

        return <div className={styles.counter}>
            <div className={styles.counterPlusMinus} onClick={() => {
                setTicketCount(tickets[id] + 1);
            }}><div>+</div></div>
            <input type="text" className={styles.counterText} value={tickets[id]} onChange={e => {
                if (!isNaN(e.target.value) && Number.isInteger(Number(e.target.value)))
                    setTicketCount(Number(e.target.value));
            }}></input>
            <div className={styles.counterPlusMinus} onClick={() => {
                setTicketCount(tickets[id] - 1);
            }}><div>-</div></div>
        </div>
    }

    function infoInput(type, id) {
        if (type === 'textarea')
            return <textarea className={styles.textarea} rows="4" value={info[id]} onChange={(e) => {
                setInfo({ ...info, [id]: e.target.value });
            }} />
        return <input type={type} value={info[id]} onChange={(e) => {
            setInfo({ ...info, [id]: e.target.value });
        }} />
    }

    function yesNoInput(id) {
        return <>
            <div style={{ display: 'inline-block' }} onClick={() => {
                setInfo({ ...info, [id]: true });
            }}>
                <input type="radio" checked={info[id]} readOnly />
                Yes
            </div>
            <div style={{ display: 'inline-block', marginLeft: '0.5em' }} onClick={() => {
                setInfo({ ...info, [id]: false });
            }}>
                <input type="radio" checked={!info[id]} readOnly />
                No
            </div>
        </>
    }

    let display;
    switch (state)
    {
        case 'browsing':
            display = <div className={styles.container}>
                <center style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Purchase Livestream Tickets</center>
                <center style={{ fontSize: '0.8em', marginBottom: '1em' }}>⚠️ This page is for livestream tickets only! For in-person tickets, <a href="/tickets">click here</a>. ⚠️</center>
                <div>
                    {ticketCounter('adult')} Adults <span style={{ fontSize: '0.8em' }}>ages 18+</span> (${ticketCosts.adult})<br/>
                    {ticketCounter('student')} Students (${ticketCosts.student})<br/>
                    {ticketCounter('unwaged')} Unwaged (${ticketCosts.unwaged})
                </div>
                <div>
                    <div className={styles.label}>{info.names.length > 1 ? 'Email addresses' : 'Email address'}:</div>
                    {info.names.map((name, i) => <div key={i}>
                        <input type="text" value={info.names[i]} onChange={(e) => {
                            info.names[i] = e.target.value;
                            setInfo({ ...info });
                        }} />
                        <br/>
                    </div>)}
                    <br/>
                    <div>Discount code:</div>
                    <input type="text"
                        value={discount}
                        onChange={(e) => { setDiscount(e.target.value); }}
                    />
                </div>
                <br/>
                <button onClick={checkout}>Checkout</button>
                {error ? <div className={styles.formErrors}>
                    Please resolve the following errors before continuing:
                    <ul>{error.split('\n').map(err => <li key={err}>{err}</li>)}</ul>
                </div> : null}
            </div>;
            break;
        case 'terms':
            display =
                <div className={styles.container}>
                    <h2 style={{ marginTop: 0 }}>Terms and Conditions</h2>
                    <div className={styles.terms}>
                        <h3>Access</h3>
                        <p>You agree that the information you have provided on this form is complete and correct. If the email address provided is incorrect, or incapable of connecting with a Google or YouTube account, we may be unable to provide stream access.</p>
                        <p>A livestream ticket covers live viewing of the main SudokuCon sessions which take place in the Fairbanks Ballroom. It does not cover access to the opening reception, puzzle tables, lunches, dinners, or any other peripheral activities. It may not be used to gain in-person access to the event. There is no guarantee of interactivity between the livestream chatroom and anyone in-person at the event.</p>
                        <p>You agree not to record, restream, screen share, or otherwise distribute the livestream to anyone who does not have a ticket.</p>

                        <h3>Conduct</h3>
                        <p>Absolutely no solicitation is allowed other than by officially sanctioned SudokuCon vendors. This restriction applies to the livestream chat as well. The determination of what constitutes solicitation is at the discretion of SudokuCon staff.</p>

                        <h3>Cancellation and refunds</h3>
                        <p>All ticket sales are final and non-refundable. The ticket cost covers only your entry for the duration of SudokuCon 2025 and anything that is distributed to attendees as part of the event. You are on your own for all other costs, including but not limited to: travel, accomodation, insurance, meals (except those included in the program), tax, and incidentals.</p>
                        <p>We may make changes to the content of SudokuCon at any time and without warning, including but not limited to the schedule, timing, activities, session hosts, special guests, and prepared food. At no point shall such changes entitle you to substitution or compensation for prior expectations. You agree to not hold SudokuCon liable for any indirect, economic, special, or consequential loss or damages of any kind that are sustained as a result of such changes.</p>
                        <p>We reserve the right to revoke your ticket, remove you from the event, and refuse re-entry, without warning and without refund, if you fail to comply with the Terms and Conditions. You shall indemnify us from and against all claims, damage, losses, costs, expenses, demands or liabilities arising out of or in connection with any breach by you of the Terms and Conditions.</p>
                        <p>If any provision of the Terms and Conditions is found to be unenforceable by law, the rest of the provisions shall still remain in effect.</p>

                        <h3>Privacy policy</h3>
                        <p>This website does not use cookies or collect any personal information from users. We do not share information about individual users with third parties.</p>
                        <p>If you donate to SudokuCon via PayPal, we may be able to see your name, address, phone number, email address, and other personal information you have provided to PayPal. We use this information only for the purpose of collecting payments via PayPal and providing donation rewards. Please review PayPal's <a href="https://www.paypal.com/us/legalhub/privacy-full" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://www.paypal.com/us/webapps/mpp/ua/acceptableuse-full?_ga=1.130764367.1787085997.1696273619" target="_blank" rel="noreferrer">Acceptable Use Policy</a> for more details.</p>
                    </div>
                    <button onClick={() => { setState('checkout'); }}>I agree to the terms and conditions.</button>
                </div>;
            break;
        case 'checkout':
            display = <div className={styles.container}>
                Order summary:
                {info.names.map((name, i) => <div key={i}>Name: {name}</div>)}
                <div>Phone: {info.phone}</div>
                <div>Email: {info.email}</div>
                {Object.keys(tickets).map(type => tickets[type] ? <div key={type}>
                    <div style={{ display: 'inline-block', width: '6em' }}>
                        ${(tickets[type] * ticketCosts[type]).toFixed(2)}
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        {tickets[type]}x {type} ticket
                    </div>
                </div> : null)}
                {discount.toUpperCase() === 'SPONSOR' ? <>
                    <hr style={{ width: '15em', marginLeft: 0 }} />
                    <div style={{ display: 'inline-block', width: '6em' }}>
                        -${totalCost().toFixed(2)}
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        SPONSOR discount applied
                    </div>
                </> : null }
                <hr style={{ width: '15em', marginLeft: 0 }} />
                <div>
                    <div style={{ display: 'inline-block', width: '6em' }}>
                        ${totalCost().toFixed(2)}
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        Total
                    </div>
                </div>
                <button className={styles.backButton} onClick={() => { setState('browsing'); }}>Go Back</button>
                <div className={styles.paypalButtons}>
                    <PayPalButtons
                        createOrder={userCreateOrder}
                        options={{ shippingPreference: 'NO_SHIPPING' }}
                        onApprove={userApprove}
                        onSuccess={(details) => { console.log('success') }}
                        catchError={(err) => { console.log('catcherror') }}
                        onError={(err) => { setState('error'); setError(err.message); }}
                    />
                </div>
            </div>
            break;
        case 'submitted':
            display = <div className={styles.container}>Waiting for server...</div>
            break;
        case 'approved':
            display = <div className={styles.successContainer}>
                Your attendance has been recorded, and you will receive an link to the YouTube stream shortly. We look forward to seeing you there!
            </div>
            break;
        default:
            // TODO If the problem persists, RegFox
            display = <div className={styles.errorContainer}>
                An error occured: {error ? error : 'Unknown error.'}
            </div>
            break;
    }

    return <PayPalScriptProvider options={{ clientId: PAYPAL_CLIENT_ID }}>
        <Base>
            {display}
        </Base>
    </PayPalScriptProvider>;
}
