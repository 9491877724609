import Base from '../components/Base.js';
import TransitAccordian from '../components/TransitAccordian.js';
import TransitMap from '../images/transit/dedham-train-to-hilton.png';

const FROM_TRAIN_STATION = <>
    When you reach the Dedham train station, walk through the parking lot to Allied Drive.  You'll
    walk uphill on that street, and the hotel is to your left.  The entry is a
    driveway to the hotel's garage, with a walkway to the front door;  there are
    mailboxes at the head of the driveway.  If you reach the lawn with the Hilton flag
    flying, you've gone too far.  It's about a 7-minute walk.
    Here is a map, from the MBTA's trip planner. The train station is the end of the
    purple line at top left, and the hotel is marked B at the bottom:
    <br/><br/>
    <img src={TransitMap} alt="map" style={{ width: '100%' }}></img>
</>

export default function Transit() {
    return <Base>
        <h1>Getting to the Hilton Boston/Dedham:</h1>
        <TransitAccordian
            upper={<div>From Logan Airport in Boston (BOS)</div>}
            lower={<div>
                If you are travelling internationally, sim cards may be purchased at the Boston airport.
                <br/><br/>
                Take the Silver Line (bus rapid transit) from your terminal to
                South Station; this takes about 30 minutes, depending on traffic. Follow signs from the platform
                to the train station (commuter rail and Amtrak). The Silver Line
                inbound from the airport is free.
                <br/><br/>
                Take the train to South Station, the very last stop.
                Then take the Franklin/Foxboro line to Dedham Corporate Center, about 25 minutes.
                Trains run roughly hourly on weekdays, about every 2 hours on weekends.  Fare
                from Boston is $7.
                <br/><br/>
                {FROM_TRAIN_STATION}
            </div>}
        />
        <TransitAccordian
            upper={<div>From T. F. Green Airport in Providence, RI (PVD)</div>}
            lower={<div>
                Take the MBTA commuter train (Providence line)
                to Back Bay or South Station, in Boston, and then take the Franklin/Foxboro line outbound
                from there to Dedham Corporate Center. The total fare is $19.75. This can take as long as three hours; if you have to fly through Providence,
                you may prefer to rent a car.
                <br/><br/>
                {FROM_TRAIN_STATION}
            </div>}
        />
        <TransitAccordian
            upper={<div>From Amtrak</div>}
            lower={<div>
                Stay on the train to South Station, the very last stop.
                Then take the Franklin/Foxboro line to Dedham Corporate Center, about 25 minutes.
                Trains run roughly hourly on weekdays, about every 2 hours on weekends.  Fare
                from Boston is $7.
                <br/><br/>
                {FROM_TRAIN_STATION}
            </div>}
        />
        <h1>Nearby attractions</h1>
        <p>
            If you're coming to SudokuCon 2025, you may want to take a day or two to explore Boston, which is
            a half-hour train ride from the convention hotel.  <a href="/Boston_Attractions.pdf" target="_blank">Click here to see some of the many attractions that Boston has to offer.</a>
        </p>
        <h1>More About Boston Transit</h1>
        <TransitAccordian
            upper={<div>Transit in Boston</div>}
            lower={<div>
                Boston's public transport system is officially called <a href="https://www.mbta.com/" target="_blank" rel="noreferrer">the MBTA</a>, for
                Massachusetts Bay Transportation Authority, but everyone knows it as
                "the T." The T's logo <img src="https://upload.wikimedia.org/wikipedia/commons/6/64/MBTA.svg" alt="(T)" style={{ height: '1em' }}></img> is a black capital T on a white circle &ndash; you'll
                see this marking subway station entrances and bus stops all over
                town. Base fare is $2 for buses, $2.75 for trains and trolleys, if you
                pay cash, less if you have a Charlie Card or a pass. And when you get
                on at Logan Airport, the Silver Line is free (and the transfer to
                other transit lines is free as well).
                <br/><br/>
                The T system consists of the four color-coded lines, collectively "the
                subway" even though they're not always below ground; the Silver Line,
                which is a bus service with dedicated lanes; the ordinary buses, which
                are yellow; and the commuter rail, color-coded purple, mainly serving
                the suburbs. The four subway lines all come in to central Boston; very
                roughly, the Orange Line goes north-south, the Red Line goes
                northwest-southeast, the Blue Line goes east-west, and the Green Line
                goes northeast-southwest. Many stations in the center of town are
                close enough to each other that it's simpler to walk, weather
                permitting.
                <br/><br/>
                Cash fares are $1.70 for buses, $2.40 for the subway. You can pay cash
                on a bus, but on the subway you need to buy a ticket at a fare machine
                as you go in.  It is simplest to pay with a Charlie Card.
                These plastic cards are available from the fare vending machines or
                from T staff.
                You can load money for several fares onto your Charlie Card, or you can buy a seven-day
                pass for $22.50, which gets you unlimited travel for a week for the
                cost of about 11 bus trips or 8 subway trips (comparing the cash
                prices).  The T has also recently introduced a contactless payment system, using
                your credit card or phone.
                <br/><br/>
                You pay when you get on. Except for the commuter rail and a few
                suburban bus lines, fares are not zoned: it's the same fare to go one
                stop as to ride the entire length of the line.
                All T service runs from about 5:00 AM to just after midnight. See <a href="http://www.mbta.com/" target="_blank" rel="noreferrer">the
                T's web site</a>, for maps and schedules.  At <a href="https://mbta.com/maps" target="_blank" rel="noreferrer">https://mbta.com/maps</a> you'll find maps of the subway and of the system
                as a whole.
                <br/><br/>
                Signage is generally good. Most subway stations have system maps and
                maps of the local neighborhood. Trains are said to go "inbound" or
                "outbound," where inbound means towards Park Street or Downtown
                Crossing, the main stations in central Boston, and outbound means away
                from the center of town. Trains and station platforms are also
                labelled by their destinations, so for example platforms on the Orange
                Line will say "to Oak Grove" or "to Forest Hills," not "northbound" or
                "southbound." The southern part of the Red Line is divided into two
                branches, as is the northern part of the Green Line, and the western
                part of the Green Line has four branches; if  you're going beyond
                central Boston, you'll want to check which train you're getting on.
                <br/><br/>
                The Red, Blue, and Orange Lines (which are trains with third-rail
                power) are more or less adequately wheelchair accessible. Most newer
                Green Line trolleys and all in-town Green Line stations are, too, but
                some stops further out, when the Green Line goes above ground, are
                more difficult. All buses, including Silver Line buses, are
                accessible.
            </div>}
        />
        <TransitAccordian
            upper={<div>History and Lore</div>}
            lower={<div>
                The Green Line is the oldest subway system in the US. Trolleys have
                been running in Boston since 1889; the earliest route included what's
                now the Beacon Street branch of the Green Line. The trolleys moved
                underground in 1897, in the tunnel under Tremont Street that now goes
                through Boylston and Park Street stations.
                <br/><br/>
                Boston participated in the Presidents' Conference Committee in the
                1930s, the group that designed a type of streetcar once ubiquitous in
                the US.  Some PCC cars are still in service in Boston on the Mattapan line,
                a spur that runs from Ashmont at the end of the Red Line into the
                Mattapan section of Boston, though they are being phased out. Two old
                cars can also be seen in storage on a siding at Boylston station on the Green Line.
                <br/><br/>
                "Charlie Ticket" and "Charlie Card" refer to an old song, "Charlie on
                the MTA," by Jackie Steiner and Bess Hawes. The MTA was an earlier
                name for the organization that became the MBTA, and the song was
                written for Walter O'Brien, running for mayor in 1949. Part of
                O'Brien's platform was opposition to recent fare increases, which had
                introduced exit fares &ndash; on some lines, passengers would pay at
                entrance, and again at exit if they'd gone far enough. (Exit fares
                remained in use on the Green Line into the 1990s.) The song is about a
                Bostonian called Charlie who gets on the train at Kendall Square,
                heading for Jamaica Plain. When the trolley comes above ground, the
                conductor calls for "one more nickel" and Charlie, with no money in
                his pocket, can't get off the train. He stays forever; his wife comes
                to Scollay Square (now Government Center) every day to give him a
                sandwich, but Charlie is "the man who never returned." The song became
                popular outside Boston when the Kingston Trio recorded it in 1959.
            </div>}
        />
    </Base>
}
